import { post, App_ID, get } from "./publicHeader";
// var Token = getToken("access_token");

// 系统设置

class SysConfig {
  /**
   * 
   * @param {应用ID} app_id 
   *获取分类菜单
   */
  async getCate() {
    try {
      const res = await post('/shop/user.cate/index', {
        app_id: App_ID
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  async getCateChild(parent_id) {
    try {
      const res = await post('/shop/user.cate/get_child', {
        parent_id: parent_id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 获取微信配置信息
  async getWxConfig(paysn) {
    try {
      const res = await post('/shop/user.wxpay/wxpay', {
        app_id: App_ID,
        paysn
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 微信授权
  async getWxAppid(callback) {
    try {
      const res = await get('/wechat/user.login/index', {
        app_id: sessionStorage.getItem('app_id'),
        callback
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 获取微信用户信息
  async getWxInfo(code, state, appid) {
    try {
      const res = await get('/wechat/user.login/login', {
        app_id: App_ID,
        code,
        state,
        appid
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 绑定手机号
  async goBindPhone(mobile, verify) {
    try {
      const res = await get('/user/user.wx_user/bindMobile', {
        app_id: App_ID,
        wx_token: sessionStorage.getItem('access_token'),
        mobile,
        verify
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 获取JS公众号配置信息
  async getTencentConfig(url, apis = "chooseWXPay", debug = false) {
    try {
      const res = await post('/wechat/user.jssdk/index', {
        app_id: App_ID,
        url,
        apis: apis,
        debug: debug
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 关注收款二维码
  async attentionQr(url) {
    try {
      const res = await get('/agent.wechat_qrcode/getQrcode', {
        app_id: App_ID,
        url,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  //前端带code请求注册接口
  async registerCode(code) {
    try {
      const res = await get('wechat/user.login/requestRegister', {
        app_id: App_ID,
        code,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获取微信APPID
  async getWeChatAppid(appid) {
    try {
      const res = await get('/wechat/user.login/get_appid', {
        app_id: appid,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  async getWeiXinConfig(name) {
    return await post('api/tool/get_button', {
      app_id: App_ID,
      button_name: name
    })
  }
  async getAppconfigButton(name) {
    return await post('client/goods.index/credit_shop', {
      app_id: App_ID,
      button_name: name
    })
  }
  
}

export default new SysConfig()

import SysConfig from "@/api/config";
import wx from "weixin-js-sdk";
import { Toast } from "vant";

// 微信H5支付
async function getWxConfig(ordersn) {
	try {
		const res = await SysConfig.getWxConfig(ordersn);
		if (res.code == 200) {
			// 调用支付
			window.location.href = res.data;
		} else {
			throw res.msg;
		}
	} catch (err) {
		console.log("抛出错误日志", err);
	}
}

// 微信公众号支付（JSAPI支付）
async function goWxConfigJsApi(str) {
	try {
		const url = window.location.href;
		const res = await SysConfig.getTencentConfig(url, str);
		if (res.code == 200) {
			const { debug, appId, nonceStr, jsApiList, timestamp, signature } = JSON.parse(res.data);
			wx.config({
				debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: appId, // 必填，公众号的唯一标识
				timestamp: timestamp, // 必填，生成签名的时间戳
				nonceStr: nonceStr, // 必填，生成签名的随机串
				signature: signature, // 必填，签名
				jsApiList: jsApiList // 必填，需要使用的JS接口列表
			});
			wx.error(function(res) {
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
				throw res.msg;
			});
		} else {
			throw res.msg;
		}
	} catch (error) {
		console.log(error);
	}
}



function wxPayTwo(res) {
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
    }
  } else {
    onBridgeReady(res);
  }
}

// 服务商版本支付
function onBridgeReady(res) {
	const { appId, nonceStr, paySign, prepay_id, timeStamp, ordersn, signType } = res;
  // eslint-disable-next-line
	WeixinJSBridge.invoke(
		"getBrandWCPayRequest",
			{
				"appId": appId,     //公众号名称，由商户传入     
				"timeStamp": timeStamp,         //时间戳，自1970年以来的秒数     
				"nonceStr": nonceStr, //随机串     
				"package": `prepay_id=${prepay_id}`,     
				"signType": signType,         //微信签名方式：     
				"paySign": paySign //微信签名 
		},
		function(res) {
			if (res.err_msg == "get_brand_wcpay_request:ok") {
				// 使用以上方式判断前端返回,微信团队郑重提示：
				window.location.href = "https://jjtc.58zltc.com/pay_success?ordersn=" + ordersn
			} else {
				Toast.fail('支付失败...');
				setTimeout(()=> {wx.closeWindow()}, 1000);
			}
		}
	);
}

// 调用小程序支付
function WxMiniPay(res) {
	wx.miniProgram.getEnv(function(mini_status) {
		// 小程序环境
		if (mini_status.miniprogram) {
			console.log('小程序环境xxxx')
			wx.miniProgram.navigateTo({url: '/pages/pay/pay_h5/index'})
		} else {
			wxPayTwo(res)
		}
	})
}


export { getWxConfig, goWxConfigJsApi, wxPayTwo, WxMiniPay };
